.gridContainer{
    width:100%;
    max-width: 60vh;
    max-height: 60vh;
    position: relative;
    margin:auto;
}
.gridContainer:after{
    content:"";
    display:block;
    padding-bottom: 100%;
}

.grid {
    position:absolute;
    display: grid;
    justify-content: stretch;
    justify-items: stretch;
    align-items: stretch;
    grid-auto-flow: row;
    row-gap: 2px;
    width:100%;
    height:100%;
}

.row {
    align-self: stretch;
    grid-row: auto;
    display: grid;
    grid-auto-flow: column;
    column-gap: 2px;
}

.cell{
    align-self: stretch;
    grid-column: auto;
    background-color: whitesmoke;
}
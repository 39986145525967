.App {
  text-align: center;
}

header, footer {
  background-color: #282c34;
  height: 10vh;
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

main{
  background-color: black;
  height: 80vh;
}

a {
  color: cornflowerblue;
}

a:visited {
  color: darkslateblue;
}
